import { Box } from "@mui/material";
import { NAVBAR_HEIGHT } from "../../components/navbar/Navbar";

export default function DashboardUsageGuide() {
    return (
        <Box sx={{ margin: "-32px -16px -32px 0"}}>
            <iframe
                src="https://mybliss-documents-prod.s3.ap-southeast-1.amazonaws.com/thirdvoice+dashboard+guide+v1.pdf"
                style={{ 
                    width: "100%", 
                    height: `calc(100vh - ${NAVBAR_HEIGHT})`, 
                    outline: "none",
                    border: "none"
                }}
            />
        </Box>
    );
}